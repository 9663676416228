<!--
 * @Author: your name
 * @Date: 2021-03-03 09:26:09
 * @LastEditTime: 2021-03-24 14:20:31
 * @LastEditors: Please set LastEditors
 * @Description: 采购月报
 * @FilePath: \sd-vue-admin\src\views\project\report\manage\dayList\components\PurchasingDaily.vue
-->
<template>
  <div>
    <el-row>
      <el-form ref="form" inline :model="form">
        <el-form-item prop="start_date">
          <el-date-picker
            v-model="form.start_date"
            type="month"
            placeholder="选择月"
            value-format="yyyy-MM-dd"
            style="width: 150px"
          ></el-date-picker>
        </el-form-item>
        <el-form-item prop="supp_id">
          <el-select
            v-model="form.supp_id"
            placeholder="请选择供应商"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in supplier"
              :key="index"
              :label="item.supp_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="brand_id">
          <el-select
            v-model="form.brand_id"
            placeholder="请选择品牌"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in Brand"
              :key="index"
              :label="item.brand_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="keyword">
          <goodslist
            :f-key="form.keyword"
            @select-goods-all="selectGoods($event)"
            @choose-goods-keyword="keyname"
          ></goodslist>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handlerInquire">查 询</el-button>
          <el-button type="warning" @click="handlerReset">重 置</el-button>
          <slot
            name="exportBtn"
            :start_date="form.start_date"
            :supp_id="form.supp_id"
            :keyword="form.keyword"
            :brand_id="form.brand_id"
            :goods_id="form.goods_id"
          ></slot>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <h3>合计：</h3>
      <el-table stripe :data="tableData.total" style="width: 50%">
        <el-table-column
          v-for="(list, index) in colemd"
          :key="index"
          :label="list.label"
          :align="list.align"
          :prop="list.prop"
          width=""
        ></el-table-column>
      </el-table>
      <h3>明细：</h3>
      <el-table
        stripe
        :data="tableData.detailed"
        :span-method="arraySpanMethod"
      >
        <el-table-column
          v-for="(list, index) in colemd1"
          :key="index"
          :label="list.label"
          :align="list.align"
          :prop="list.prop"
          width=""
        ></el-table-column>
      </el-table>
    </el-row>
  </div>
</template>

<script>
  import goodslist from '@/components/goodslist/index.vue'
  import { postAction } from '@/api/Employee'
  export default {
    components: {
      goodslist,
    },
    data() {
      return {
        form: {
          start_date: this.qianyitian(),
          supp_id: '', //供应商ID
          brand_id: '', //品牌
          keyword: '', //商品关键词
          goods_id: '', //商品ID
        },
        tableData: {},
        userdata: [],
        department: [],
        supplier: [],
        Brand: [],
        url: {
          department: '/baseAdmin/depart/index',
          userlist: '/baseAdmin/common/user-list',
          supplier: '/purchaseAdmin/base/supp-list',
          Brand: '/baseAdmin/common/brand-list',
          list: '/reportAdmin/operate/purchase-month',
        },
        colemd: [
          {
            order: 1,
            label: '供应商',
            align: 'center',
            prop: 'supp_name',
            width: '',
          },
          {
            order: 2,
            label: '采购金额',
            align: 'center',
            prop: 'final_money',
            width: '',
          },
          {
            order: 3,
            label: '付款金额',
            align: 'center',
            prop: 'pay_money',
            width: '',
          },
          {
            order: 4,
            label: '新增应付款',
            align: 'center',
            prop: 'begin_arrears_amount',
            width: '',
          },
          {
            order: 5,
            label: '应付款余额',
            align: 'center',
            prop: 'arrears_amount',
            width: '',
          },
        ],
        colemd1: [
          {
            order: 1,
            label: '供应商',
            align: 'center',
            prop: 'supp_name',
            width: '',
          },
          {
            order: 2,
            label: '采购单',
            align: 'center',
            prop: 'bill_code',
            width: '',
          },
          {
            order: 3,
            label: '入库仓库',
            align: 'center',
            prop: 'depot_name',
            width: '',
          },
          {
            order: 4,
            label: '品牌',
            align: 'center',
            prop: 'brand_name',
            width: '',
          },
          {
            order: 5,
            label: '商品名称',
            align: 'center',
            prop: 'goods_name',
            width: '',
          },
          {
            order: 6,
            label: '规格',
            align: 'center',
            prop: 'goods_specs',
            width: '',
          },
          {
            order: 7,
            label: '单位换算',
            align: 'center',
            prop: 'goods_unit_change',
            width: '',
          },
          {
            order: 8,
            label: '单位',
            align: 'center',
            prop: 'goods_unit_name',
            width: '',
          },
          {
            order: 9,
            label: '采购价',
            align: 'center',
            prop: 'goods_price',
            width: '',
          },
          {
            order: 10,
            label: '采购数量',
            align: 'center',
            prop: 'goods_num',
            width: '',
          },
          {
            order: 11,
            label: '采购金额',
            align: 'center',
            prop: 'sum_money',
            width: '',
          },
          {
            order: 12,
            label: '采前成本价',
            align: 'center',
            prop: 'pre_price',
            width: '',
          },
          {
            order: 13,
            label: '采后成本价',
            align: 'center',
            prop: 'new_price',
            width: '',
          },
          {
            order: 14,
            label: '成本变动',
            align: 'center',
            prop: 'change',
            width: '',
          },
        ],
        spanArr: [],
        spanArr1: [],
        spanArr2: [],
        spanArr3: [],
      }
    },

    computed: {},
    watch: {
      'form.keyword'(val) {
        if (val == '') {
          this.form.goods_id = ''
        }
      },
    },
    created() {},
    mounted() {
      this.handlerdepartment()
      this.hanlderuserlist()
      this.handlerlist()
      this.handlersupplier()
      this.handlerBrand()
    },
    methods: {
      // 合并列
      setrowspans(list) {
        let contactDot = 0
        let spanArr = []
        list.forEach((item, index) => {
          if (index === 0) {
            spanArr.push(1)
          } else {
            if (item.supp_name === list[index - 1].supp_name) {
              spanArr[contactDot] += 1
              spanArr.push(0)
            } else {
              contactDot = index
              spanArr.push(1)
            }
          }
        })
        this.spanArr = spanArr
      },
      getSpanArr(data) {
        let Arr = new Array()
        let pos = 0
        let Arr1 = new Array()
        let pos1 = 0
        let Arr2 = new Array()
        let pos2 = 0
        let Arr3 = new Array()
        let pos3 = 0
        console.log(data, '执行了吗')
        try {
          for (var i = 0; i < data.length; i++) {
            if (i == 0) {
              Arr.push(1)
              pos = 0
              Arr1.push(1)
              pos1 = 0
              Arr2.push(1)
              pos2 = 0
              Arr3.push(1)
              pos3 = 0
            } else {
              // 判断当前元素与上一个元素是否相同
              if (data[i].supp_name == data[i - 1].supp_name) {
                Arr[pos] += 1
                Arr.push(0)
              } else {
                Arr.push(1)
                pos = i
              }
              if (data[i].bill_code === data[i - 1].bill_code) {
                Arr1[pos1] += 1
                Arr1.push(0)
              } else {
                Arr1.push(1)
                pos1 = i
              }
              if (data[i].depot_name === data[i - 1].depot_name) {
                Arr2[pos2] += 1
                Arr2.push(0)
              } else {
                Arr2.push(1)
                pos2 = i
              }
              if (data[i].brand_name === data[i - 1].brand_name) {
                Arr3[pos3] += 1
                Arr3.push(0)
              } else {
                Arr3.push(1)
                pos3 = i
              }
            }
            // if (data[i].bill_code === data[i - 1].bill_code) {
            //   this.spanArr1[this.pos1] += 1
            //   this.spanArr1.push(0)
            // } else {
            //   this.spanArr1.push(1)
            //   this.pos1 = i
            // }
            // if (data[i].depot_name === data[i - 1].depot_name) {
            //   this.spanArr2[this.pos2] += 1
            //   this.spanArr2.push(0)
            // } else {
            //   this.spanArr2.push(1)
            //   this.pos2 = i
            // }
            // if (data[i].brand_name === data[i - 1].brand_name) {
            //   this.spanArr3[this.pos3] += 1
            //   this.spanArr3.push(0)
            // } else {
            //   this.spanArr3.push(1)
            //   this.pos3 = i
            // }
          }
          this.spanArr = Arr
          this.spanArr1 = Arr1
          this.spanArr2 = Arr2
          this.spanArr3 = Arr3
        } catch (error) {}
      },

      arraySpanMethod({ row, column, rowIndex, columnIndex }) {
        // console.log({ row, column, rowIndex, columnIndex })
        if (columnIndex === 0) {
          let _row = this.spanArr[rowIndex]
          let _col = _row > 0 ? 1 : 0
          return {
            // [0,0] 表示这一行不显示， [2,1]表示行的合并数
            rowspan: _row,
            colspan: _col,
          }
        }
        // else if (columnIndex === 1) {
        //   let _row1 = this.spanArr1[rowIndex]
        //   let _col1 = _row1 > 0 ? 1 : 0
        //   return {
        //     // [0,0] 表示这一行不显示， [2,1]表示行的合并数
        //     rowspan: _row1,
        //     colspan: _col1,
        //   }
        // } else if (columnIndex === 2) {
        //   let _row2 = this.spanArr2[rowIndex]
        //   let _col2 = _row2 > 0 ? 1 : 0
        //   return {
        //     // [0,0] 表示这一行不显示， [2,1]表示行的合并数
        //     rowspan: _row2,
        //     colspan: _col2,
        //   }
        // } else if (columnIndex === 3) {
        //   let _row3 = this.spanArr3[rowIndex]
        //   let _col3 = _row3 > 0 ? 1 : 0
        //   return {
        //     // [0,0] 表示这一行不显示， [2,1]表示行的合并数
        //     rowspan: _row3,
        //     colspan: _col3,
        //   }
        // }
      },
      keyname(val) {
        console.log(val, '商品名称')
        this.form.keyword = val
      },
      selectGoods(row) {
        console.log('商品', row)
        if (row.goods_id == undefined) {
          this.form.goods_id = -1
        } else {
          this.form.goods_id = row.goods_id
        }
      },
      handlerReset() {
        this.$refs['form'].resetFields()
        this.handlerlist()
      },
      handlerInquire() {
        this.handlerlist()
      },
      handlerlist() {
        postAction(this.url.list, this.form)
          .then((res) => {
            console.log(res)
            this.tableData = res.data
            this.getSpanArr(this.tableData.detailed)
          })
          .catch((err) => {
            console.log(err)
          })
      },
      //品牌
      handlerBrand() {
        postAction(this.url.Brand, { pageSize: -1 })
          .then((res) => {
            console.log(res, '品牌')
            this.Brand = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
      //供应商
      handlersupplier() {
        postAction(this.url.supplier, {})
          .then((res) => {
            console.log(res, '供应商')
            this.supplier = res.data.rows
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      //部门
      handlerdepartment() {
        postAction(this.url.department, { is_close: 0 })
          .then((res) => {
            console.log('部门', res)
            this.department = res.data.list
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      //收款人
      hanlderuserlist() {
        postAction(this.url.userlist, {})
          .then((res) => {
            console.log('收款人', res)
            this.userdata = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      qianyitian() {
        let d = new Date()
        let m = d.getMonth() + ''
        if (m.length == 1) {
          m = '0' + m
        }
        let yesterday = d.getFullYear() + '-' + m
        return yesterday
        // if (new Date().getMonth() == 1) {
        //   var time = new Date().getTime() - 24 * 60 * 60 * 1000 * 28
        // } else {
        //   var time = new Date().getTime() - 24 * 60 * 60 * 1000 * 30
        // }
        // var yesterday = new Date(time)
        // var month = yesterday.getMonth()
        // var day = yesterday.getDate()
        // yesterday =
        //   yesterday.getFullYear() +
        //   '-' +
        //   (yesterday.getMonth() > 9
        //     ? yesterday.getMonth() + 1
        //     : '0' + (yesterday.getMonth() + 1)) +
        //   '-' +
        //   (yesterday.getDate() > 9
        //     ? yesterday.getDate()
        //     : '0' + yesterday.getDate())
        // return yesterday
      },
    },
  }
</script>
<style lang="scss" scoped></style>
