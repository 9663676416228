<!--
 * @Author: your name
 * @Date: 2021-02-18 09:08:43
 * @LastEditTime: 2021-03-03 16:41:31
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \sd-vue-admin\src\views\project\report\manage\monthList\index.vue
-->
<template>
  <!-- NAME[epic=报表] 月报 -->
  <div class="orderTest-container">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="销售月报" name="first">
        <sales-daily ref="sales">
          <el-button
            slot="exportBtn"
            slot-scope="props"
            @click="handleExport(1, props)"
          >
            导 出
          </el-button>
        </sales-daily>
      </el-tab-pane>
      <el-tab-pane label="财务月报" name="second">
        <financai-daily ref="financai">
          <el-button
            slot="exportBtn"
            slot-scope="props"
            @click="handleExport(2, props)"
          >
            导 出
          </el-button>
        </financai-daily>
      </el-tab-pane>
      <el-tab-pane label="采购月报" name="third">
        <purchasing-daily ref="purchasing">
          <el-button
            slot="exportBtn"
            slot-scope="props"
            @click="handleExport(3, props)"
          >
            导 出
          </el-button>
        </purchasing-daily>
      </el-tab-pane>
      <el-tab-pane label="仓库月报" name="fourth">
        <warehouse-daily ref="warehouse">
          <el-button
            slot="exportBtn"
            slot-scope="props"
            @click="handleExport(4, props)"
          >
            导 出
          </el-button>
        </warehouse-daily>
      </el-tab-pane>
      <el-tab-pane label="行动月报" name="right">
        <action-daily ref="action">
          <el-button
            slot="exportBtn"
            slot-scope="props"
            @click="handleExport(5, props)"
          >
            导 出
          </el-button>
        </action-daily>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import ActionDaily from './components/ActionDaily.vue'
  import WarehouseDaily from './components/WarehouseDaily.vue'
  import PurchasingDaily from './components/PurchasingDaily.vue'
  import FinancaiDaily from './components/FinancialDaily.vue'
  import SalesDaily from './components/SalesDaily.vue'
  import { downloadFile } from '@/api/Employee'
  export default {
    components: {
      SalesDaily,
      FinancaiDaily,
      PurchasingDaily,
      WarehouseDaily,
      ActionDaily,
    },
    data() {
      return {
        activeName: 'first',
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
      handleClick(tab, event) {
        console.log(tab, event)
      },
      handleExport(type, props) {
        switch (type) {
          // 销售
          case 1:
            downloadFile('/reportAdmin/operate/sale-export', '销售月报.xlsx', {
              ...props,
              ...{ type: 3 },
            })
            break
          // 财务月报
          case 2:
            downloadFile(
              '/reportAdmin/operate/finance-export',
              '财务月报.xlsx',
              {
                ...props,
                ...{ type: 3 },
              }
            )
            break
          // 采购月报
          case 3:
            downloadFile(
              '/reportAdmin/operate/purchase-export',
              '采购月报.xlsx',
              {
                ...props,
                ...{ type: 3 },
              }
            )
            break
          // 仓库月报
          case 4:
            downloadFile('/reportAdmin/operate/depot-export', '仓库月报.xlsx', {
              ...props,
              ...{ type: 3 },
            })
            break
          // 行动月报
          case 5:
            downloadFile('/reportAdmin/operate/actor-export', '行动月报.xlsx', {
              ...props,
              ...{ type: 3 },
            })
            break
        }
      },
    },
  }
</script>
<style lang="scss" scoped></style>
