var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-form",
            { ref: "form", attrs: { inline: "", model: _vm.form } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "start_date" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "150px" },
                    attrs: {
                      type: "month",
                      placeholder: "选择月",
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.form.start_date,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "start_date", $$v)
                      },
                      expression: "form.start_date",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "depot_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { placeholder: "请选择仓库" },
                      model: {
                        value: _vm.form.depot_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "depot_id", $$v)
                        },
                        expression: "form.depot_id",
                      },
                    },
                    _vm._l(_vm.warehouse, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.depot_name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "brand_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { placeholder: "请选择品牌" },
                      model: {
                        value: _vm.form.brand_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "brand_id", $$v)
                        },
                        expression: "form.brand_id",
                      },
                    },
                    _vm._l(_vm.Brand, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.brand_name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "keyword" } },
                [
                  _c("goodslist", {
                    attrs: { "f-key": _vm.form.keyword },
                    on: {
                      "select-goods-all": function ($event) {
                        return _vm.selectGoods($event)
                      },
                      "choose-goods-keyword": _vm.keyname,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handlerInquire },
                    },
                    [_vm._v("查 询")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "warning" },
                      on: { click: _vm.handlerReset },
                    },
                    [_vm._v("重 置")]
                  ),
                  _vm._t("exportBtn", null, {
                    start_date: _vm.form.start_date,
                    goods_id: _vm.form.goods_id,
                    brand_id: _vm.form.brand_id,
                    depot_id: _vm.form.depot_id,
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c("h3", [_vm._v("合计：")]),
          _c(
            "el-table",
            {
              staticStyle: { width: "50%" },
              attrs: { stripe: "", data: _vm.tableData.total },
            },
            _vm._l(_vm.colemd, function (list, index) {
              return _c("el-table-column", {
                key: index,
                attrs: {
                  label: list.label,
                  align: list.align,
                  prop: list.prop,
                  width: "",
                },
              })
            }),
            1
          ),
          _c("h3", [_vm._v("库存变化明细：")]),
          _c(
            "el-table",
            {
              attrs: {
                stripe: "",
                data: _vm.tableData.detailed,
                "span-method": _vm.arraySpanMethod,
              },
            },
            _vm._l(_vm.colemd1, function (list, index) {
              return _c("el-table-column", {
                key: index,
                attrs: {
                  label: list.label,
                  align: list.align,
                  prop: list.prop,
                  width: "",
                },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }